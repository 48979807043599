import InnerHtml from "../dynamic/InnerHtml";
import { Icon } from "@mgmresorts/mgm-ui";

import styles from "./SavingsPlan401K_Contact.module.css";

export const SavingsPlan401K_Contact = (props) => {
  console.log(props);
  const { leftCta, leftCtaEnabled, leftReference } = props.data;

  console.log(leftCta, leftCtaEnabled, leftReference);
  const data = leftReference[0];

  return (
    <session>
      <div className={styles.contactDetails}>
      { props.data?.leftReference[0]?.icon?.icon_file?.url ? (
                <img
                src={props.data?.leftReference[0]?.icon?.icon_file?.url}
                />
              ) :props.data?.leftReference[0]?.icon?.icon && 
              <Icon
              name={props.data?.leftReference[0]?.icon?.icon}
              variant="outlined"
             size="large"
              role="img"
            />
            }
        <h2>{data?.heading}</h2>
        {/* <p className={styles.contactText}>{data?.description}</p> */}
        <InnerHtml
          description={data?.description}
          // hash={modalContent?.$?.description}
        />
      </div>
    </session>
  );
};

export default SavingsPlan401K_Contact;
