import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import arrowDown from "../../assets/images/chevron-down-24.svg";
import arrowUp from "../../assets/images/chevron-up-24.svg";
import styles from "./AccordionGroup.module.css";

import { CardContainer, Icon, TextLink } from "@mgmresorts/mgm-ui";
import { useSelector } from "react-redux";
import Modal from "../global/ui/Modal";
import InnerHtml from "./InnerHtml";
import use401K from "./use401K";
import { useLocation } from "react-router-dom";

const AccordionGroup = (props) => {
  const navigate = useNavigate();
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );
  let [showPopup, setShowPopup] = useState(false);
  let [selectedModal, setSelectedModal] = useState();
  let [accordionStatus, setAccordionStatus] = useState([]);
  let [accordionLinks, setAccordionLinks] = useState([]);
  const [expandAllB, setExpandAllB] = useState(false);

  const expandAllCs = props.data?.expandAllLabel
    ? props.data.expandAllLabel
    : globalProperties?.expand_all
    ? globalProperties?.expand_all
    : "Expand All";
  const collapAllCs = props.data?.collapseAllLabel
    ? props.data.collapseAllLabel
    : globalProperties?.collapse_all
    ? globalProperties?.collapse_all
    : "Collapse All";

  const location = useLocation();
  const cLocation = location.pathname.split("/").pop();
  var apiData;
  var isUserEnrolled;

  // for 401K - enrollment flag - choosing the array object from CS based on enrolled and not enrolled
  const ENROLLED_TEXT = "401_k_enrolled";
  const NOT_ENROLLED_TEXT = "401_k_not_enrolled";
  const csEnrollmentFlag = props.data?.custom_flags;

  //if (cLocation === "401-k-savings-plan") {
    apiData = use401K();

    isUserEnrolled = apiData?.data?.get401KDetails?.isUserEnrolled;
  //}

  useEffect(() => {
    if (props.data.children) {
      setAccordionStatus([...props.data.children.map(() => false)]);
      setAccordionLinks([...props.data.children.map(() => [])]);
      seperateAllLinks();
    }
  }, []);

  useEffect(() => {
    if (checkerFn(accordionStatus)) {
      setExpandAllB(true);
    } else {
      setExpandAllB(false);
    }
  }, [accordionStatus]);

  let checkerFn = (arr) => arr.every((v) => v === true);

  const expandCollapseFn = () => {
    if (!expandAllB) {
      setAccordionStatus([...accordionStatus.map(() => true)]);
      setExpandAllB(true);
    } else {
      setAccordionStatus([...accordionStatus.map(() => false)]);
      setExpandAllB(false);
    }
  };

  const seperateAllLinks = () => {
    let combinedLinks = [];
    for (let i = 0; i < props.data.children.length; i++) {
      if (props.data.children[i].children) {
        combinedLinks.push(seperateLinks(props.data.children[i].children));
      } else {
        combinedLinks.push([]);
      }
    }
    setAccordionLinks([...combinedLinks]);
  };

  const seperateLinks = (arr) => {
    return [
      arr.filter((_, index) => index < Math.ceil(arr.length / 2)),
      arr.filter((_, index) => index >= Math.ceil(arr.length / 2)),
    ];
  };

  const handleAccordionClick = (index) => {
    let arr = [...accordionStatus];
    arr[index] = !arr[index];
    setAccordionStatus(arr);
  };

  const popupHandler = (e, index, secIndex, subIndex) => {
    e.preventDefault();
    let cta = accordionLinks[index][secIndex][subIndex].cta;
    if (cta.openIn == "new_window") {
      //setIsModalOpen(true);
      window.open(cta.url, "_blank");
    } else if (cta.openIn == "same_window") {
      navigate(cta.url);
    } else if (cta.openIn == "modal") {
      setSelectedModal({
        heading: cta.modalContent.heading,
        body: cta.modalContent.content,
      });
      setShowPopup(true);
    }
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };

  return (
    <div>
      {(cLocation != "401-k-savings-plan" ||
        (csEnrollmentFlag?.length == 0) ||         //means comes for both enr & not enr
        (isUserEnrolled == true && csEnrollmentFlag?.includes(ENROLLED_TEXT)) ||
        (isUserEnrolled == false &&
          csEnrollmentFlag?.includes(NOT_ENROLLED_TEXT))) && (
        <div className={styles.accordionGroupHolder}>
          {props.data.children && (
            <CardContainer
              className={styles.cardContainer}
              style={{ borderRadius: "8px", margin: "0", padding: "0" }}
            >
              <div className={styles.accordionParent}>
                <div className={styles.innerMargin}>
                  <h2 data-cslp={props.data?.$?.heading}>
                    {/* <span className={styles.titletext}> */}
                    <span>{props.data?.heading} </span>
                    {/* </span> */}
                    <span
                      className={styles.expandAllLink}
                      onClick={() => expandCollapseFn()}
                    >
                      {expandAllB ? collapAllCs : expandAllCs}
                    </span>
                  </h2>

                  <InnerHtml description={props.data?.description} />
                </div>

                <hr />
                <div className={styles.innerMargin}>
                  {props.data.children.map((item, index) => (
                    <div key={index} className={styles.accordionGroup}>
                      <div
                        className={`${styles.cardContainerInner} ${
                          item.image !== null ? styles.setFlex : ""
                        }`}
                      >
                        <div>
                          <div>
                            <div
                              className={styles.accordionHeader}
                              onClick={() => handleAccordionClick(index)}
                            >
                              <h3
                                data-cslp={
                                  props.data?.$?.children[index]?.heading
                                }
                              >
                                {item.image !== null && (
                                  <div className={styles.imageHolder}>
                                    <img
                                      data-cslp={
                                        props.data?.$?.children[index]?.image
                                      }
                                      src={item.image}
                                    />
                                  </div>
                                )}
                                {item.image == null &&
                                  item.icon !== null &&
                                  (item.icon.url && item.icon.url !== "" ? (
                                    <div className={styles.imageHolder}>
                                      <img
                                        data-cslp={
                                          props.data?.$?.children[index]?.icon
                                        }
                                        src={item.icon.url}
                                      />
                                    </div>
                                  ) : (
                                    <div className={styles.imageIconHolder}>
                                      {props.data?.children[0]?.iconFile ? (
                                        <img
                                          src={
                                            props.data?.children[0]?.iconFile
                                          }
                                        />
                                      ) : (
                                        props.data?.children[0]?.icon && (
                                          <Icon
                                            data-cslp={
                                              props.data?.children[0]?.$?.icon
                                            }
                                            name={item.icon}
                                            size="small"
                                          />
                                        )
                                      )}
                                    </div>
                                  ))}
                                {item.heading}{" "}
                              </h3>
                              <div>
                                {accordionStatus[index] && (
                                  <img src={arrowUp} />
                                )}
                                {!accordionStatus[index] && (
                                  <img src={arrowDown} />
                                )}
                              </div>
                            </div>
                          </div>
                          <div>
                            <InnerHtml
                              description={item?.description}
                              style={`${styles.accordionBody} ${
                                accordionStatus[index] ? styles.open : ""
                              }`}
                              hash={props.data?.$?.children[index]?.description}
                            />

                            <div
                              className={`${styles.links} ${
                                accordionLinks[index]?.length !== 0 &&
                                accordionStatus[index]
                                  ? styles.setMargin
                                  : styles.zeroMargin
                              }`}
                            >
                              {accordionStatus[index] &&
                                accordionLinks[index].length != 0 &&
                                accordionLinks[index].map((sec, secIndex) => (
                                  <React.Fragment key={secIndex}>
                                    {sec.length !== 0 && (
                                      <div>
                                        {sec.map((item, subIndex) => (
                                          <TextLink
                                            key={subIndex}
                                            href="#"
                                            onClick={(e) =>
                                              popupHandler(
                                                e,
                                                index,
                                                secIndex,
                                                subIndex
                                              )
                                            }
                                            iconRight="arrow-right"
                                            variant="large"
                                          >
                                            {item.cta.label}
                                          </TextLink>
                                        ))}
                                      </div>
                                    )}
                                  </React.Fragment>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </CardContainer>
          )}
          {showPopup && (
            <Modal onClose={onPopupClose} heading={selectedModal.heading}>
              <InnerHtml description={selectedModal.body} />
            </Modal>
          )}
        </div>
      )}
    </div>
  );
};

export default AccordionGroup;
