import styles from "./Callout.module.css";
import { Icon } from "@mgmresorts/mgm-ui";
import InnerHtml from "../dynamic/InnerHtml";
import ChooseCta from "../../hooks/ChooseCta";
import Modal from "../global/ui/Modal";
import { useState, useRef, useEffect } from "react";

const Callout = (props) => {
  console.log("Callout @@@", props.data);
const refContainer = useRef();
  let [showPopup, setShowPopup] = useState(false);
  let [selectedModal, setSelectedModal] = useState();
  const [componentWidth, setComponentWidth]= useState(); 

  const openModal = (arg) => {
    let modal = props.data?.cta[arg]?.modalContent;
    if (modal != null && modal != undefined) {
      setSelectedModal({
        heading: modal.heading,
        body: modal.content,
      });
    } else {
      setSelectedModal({ heading: "", body: "" });
    }
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };
  useEffect(() => {
  if(refContainer.current) {
  setComponentWidth(refContainer.current.offsetWidth)
}
  },[refContainer.current])

  return (
    <section className={`${styles.callout} ${styles[props.data?.align]}`} ref={refContainer}>
     
     <div className={`${styles.iconHolder} ${styles[props.data?.align]}`}>
        { props.data?.iconFile  ? (
                <img
                src={props.data?.iconFile}
                />
              ) : props.data?.icon && 
              <Icon
              name={props.data?.icon}
              variant="outlined"
              size="large"
              role="img"
              data-cslp={props.data?.$?.icon}
            />
            }
        </div>
     
      <h2 data-cslp={props.data?.$?.heading}>{props.data?.heading}</h2>
      {props.data?.description && (
        <InnerHtml
          description={props.data?.description}
          style={styles.innerHtml}
          hash={props.data?.$?.description}
        />
      )}
      {props.data?.ctaEnabled && (
        <div className={`${styles.buttonHolder} ${componentWidth > 500 ? styles.big : styles.small }`}>
          <ChooseCta
            data={props.data?.cta}
            type="auto_type"
            openModal={openModal}
          />
        </div>
      )}
      {showPopup && (
        <Modal onClose={onPopupClose} heading={selectedModal.heading}>
          <InnerHtml description={selectedModal.body} />
        </Modal>
      )}
    </section>
  );
};

export default Callout;
